import __languages from '/mnt/hgfs/empire/var/www/macentrale/leg2/storage/app/languages.json';
import __logo from './medias/images/topmora.svg';
import Parsley from 'parsleyjs';
import 'parsleyjs/dist/i18n/fr';
import 'parsleyjs/dist/i18n/fr.extra';

const laravel = '/mnt/hgfs/empire/var/www/macentrale/leg2/'

const config = {
    laravel : {
        public : `${laravel}public/`,
        root : laravel
    }
}

export const languages = __languages;

export const logo = __logo;

export default config;